import React from 'react'
import Typewriter from 'typewriter-effect';

import './styles.module.css'
import { Container } from '@mui/material'

export default function Landing() {
  const note = "Happy valentines day! Heres a collection of our memories together in the past few years, as we look forward to the future..."

  return (
    <Container style={{height: '100%'}}>
        <div className='note text-2xl'>
            <div className="grid grid-cols-6 gap-4">
                <div className="font-mono col-start-2 col-span-4 ...">
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter
                            .typeString(note)
                            .start();
                        }}
                    />
                </div>
            </div>
        </div>
    </Container>
  )
}
