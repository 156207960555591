import { Container } from '@mui/material';
import Typewriter from 'typewriter-effect';

export default function Note() {

    const note = "So, will you be my valentine?"

    return (
        <Container style={{height: '100%'}}>
            <div className='note text-2xl'>
                <div className="grid grid-cols-6 gap-4">
                    <div className="font-mono col-start-2 col-span-4 ...">
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                .typeString(note)
                                .start();
                            }}
                        />
                    </div>
                </div>
            </div>
        </Container>
    )
}