import { createRoot } from 'react-dom/client'
import PhotoDeck from './photodeck'
import Tetris from './tetris'
import Landing from './landing'
import Note from './note'
import Map from './map'
import Fin from './fin'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { Container } from '@mui/material';

export default function Index() {
  let [step, setStep] = useState(0)
  let [isTetrisCompleted, setTetrisCompleted] = useState(false)
  let [isPhotoDeckCompleted, setPhotoDeckCompleted] = useState(false)

  useEffect(() => {
    if (step === 1) {
      setTimeout(() => {
        setStep(step+1)
      }, 30000)
    }
    if (step === 3) {
      setTimeout(() => {
        setStep(step+1)
      }, 35000)
    }
  }, [step])

  useEffect(() => {
    if (isTetrisCompleted && step === 4)
      setTimeout(() => {
        setStep(step+1)
      }, 1000)
  }, [isTetrisCompleted])

  useEffect(() => {
    if (step === 2 && isPhotoDeckCompleted)
      setStep(step+1)
  }, [isPhotoDeckCompleted])

  const updateGameStatus = (update) => {
    setTetrisCompleted(update)
  }

  const updatePhotoDeckStatus = (update) => {
    setPhotoDeckCompleted(update)
  }

  const content = () => {
    switch(step) {
      case 0:
        return <Container style={{height: '100%'}}>
            <div className='note text-2xl'>
                <div className="grid grid-cols-6 gap-4">
                    <div className="font-mono col-start-2 col-span-4">
                      <Button size="large" disableElevation onClick={() => {
                        document.getElementById("song").play()
                        setStep(step+1)
                      }}>
                        <span className={'text-4xl font-mono text-black'}>
                          Start
                        </span>
                      </Button>
                    </div>
                </div>
            </div>
        </Container>
      case 1:
        return <Landing />
      case 2:
        return <PhotoDeck setPhotoDeckCompleted={updatePhotoDeckStatus}/>
      case 3:
        return <Note />
      case 4:
        return <Tetris setGameCompleted={updateGameStatus} />
      case 5:
        return <Fin />
    }
  }

  return (
    <div style={{
      height: '100%'
    }}>
      <audio id="song" loop autoPlay={true} muted={false}> 
        <source src="/music/valentines_background.mp3" type="audio/mpeg"/>
      </audio>

      {content()}
    </div>
  )
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<Index />)
