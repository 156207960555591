import { Container } from '@mui/material';
import Typewriter from 'typewriter-effect';

export default function Note() {

    const note = "Happy valentines day baby! \n I have been all over the world, but home is always with you.. \n Here's to another valentines day and a lot more until we're grey! I Love you Ivana, Always and Forever"

    return (
        <Container style={{height: '100%'}}>
            <div className='note text-2xl'>
                <div className="grid grid-cols-6 gap-4">
                    <div className="font-mono col-start-2 col-span-4 ...">
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                .typeString(note)
                                .start();
                            }}
                        />
                    </div>
                </div>
            </div>
        </Container>
    )
}