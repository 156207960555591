import React, { useState } from 'react'
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import * as utils from './utils'
import './styles.css'

const cards = [
  {
    "url": 'pictures/DSC000001.jpg',
    "orientation": "landscape",
    "location": null
  },
  {
    "url": 'pictures/DSC03197.JPG',
    "orientation": "landscape",
    "location": null
  },
  {
    "url": 'pictures/DSCF1236.JPG',
    "orientation": "landscape",
    "location": null
  },
  {
    "url": 'pictures/DSCF2102.JPG',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/DSCF9980.JPG',
    "orientation": "landscape",
    "location": null
  },
  {
    "url": 'pictures/IMG_0800.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_0975.JPG',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_1046.JPG',
    "orientation": "landscape",
    "location": null
  },
  {
    "url": 'pictures/IMG_1081.JPG',
    "orientation": "landscape",
    "location": null
  },
  {
    "url": 'pictures/IMG_1421.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_3160.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_4853.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_4877.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_5721.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_5903.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_6214.jpeg',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_7792.JPG',
    "orientation": "portrait",
    "location": null
  },
  {
    "url": 'pictures/IMG_8360.JPG',
    "orientation": "landscape",
    "location": null
  },
]

export default function Deck({ setPhotoDeckCompleted }) {
  const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
  const [props, api] = useSprings(cards.length, (i) => ({
    ...utils.to(i),
    from: utils.from(i)
  })) // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(({ args: [index], active, movement: [mx], direction: [xDir], velocity: [vx] }) => {
    const trigger = vx > 0.2 // If you flick hard enough it should trigger the card to fly out
    if (!active && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
    api.start((i) => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(index)
      const x = isGone ? (200 + window.innerWidth) * xDir : active ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = mx / 100 + (isGone ? xDir * 10 * vx : 0) // How much the card tilts, flicking it harder makes it rotate faster
      const scale = active ? 1.1 : 1 // Active cards lift up a bit
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 }
      }
    })
    if (!active && gone.size === cards.length)
      setPhotoDeckCompleted(true)
      // setTimeout(() => {
      //   gone.clear()
      //   api.start((i) => utils.to(i))
      // }, 3000)
  })
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <Container>
      <Box style={{display: 'flex'}}>
        <div id="photo-deck">
          {props.map(({ x, y, rot, scale }, i) => (
            <animated.div key={i} style={{ x, y }}>
              {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
              <animated.div
                {...bind(i)}
                style={{
                  transform: interpolate([rot, scale], utils.trans),
                  backgroundImage: `url(${cards[i].url})`,
                  width: cards[i].orientation === 'portrait' ? '240px' : '320px',
                  height: cards[i].orientation === 'portrait' ? '320px' : '240px'
                }}
              />
            </animated.div>
          ))}
        </div>
      </Box>
    </Container>
  )
}
