import Tetris from './tetris/components/Tetris.tsx'
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';

const VerticallyCenterChildren = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Score = styled.div`
  position: relative;
  font-family: monospace;
  font-size: 18px;
  color: #888;
`;

const LeftHalf = styled.div`
  display: inline-block;
  width: 50%;
`;

const RightHalf = styled(LeftHalf)`
  text-align: right;
`;

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
`;

const LeftColumn = styled(Column)`
  min-width: 88px;
`;

const RightColumn = styled(LeftColumn)`
  padding-left: 15px;
`;

const MiddleColumn = styled(Column)`
  min-width: 200px;
`;

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 2px 7px 18px 3px #d2d2d2;
`;

const Alert = styled.h2`
  color: #666;
  margin: 0;
`;

const Button = styled.button`
  border: 1px solid #666;
  background: none;
  margin-top: 12px;
  border-radius: 4px;
`;

const Digit = styled.span`
  font-family: monospace;
  padding: 1px;
  margin: 1px;
  font-size: 24px;
`;

const Digits = ({ children, count = 4 }) => {
  let str = children.toString();

  while (str.length < count) {
    str = `${0}${str}`;
  }

  return (
    <>
      {str.split('').map((digit, index) => (
        <Digit key={index}>{digit}</Digit>
      ))}
    </>
  );
};

export default function TetrisGame({ setGameCompleted }) {

    let [gameScore, setGameScore] = useState(0)
    let [isBoardCleared , setBoardCleared] = useState(0)

    useEffect(() => {
      if (gameScore > 27 || isBoardCleared) {
        controller.pause()
        setGameCompleted(true)
      }
    }, [gameScore, isBoardCleared])

    return(
        <VerticallyCenterChildren>
            <Container>
                <Tetris
                    keyboardControls={{
                    // Default values shown here. These will be used if no
                    // `keyboardControls` prop is provided.
                    down: 'MOVE_DOWN',
                    left: 'MOVE_LEFT',
                    right: 'MOVE_RIGHT',
                    space: 'HARD_DROP',
                    z: 'FLIP_COUNTERCLOCKWISE',
                    x: 'FLIP_CLOCKWISE',
                    up: 'FLIP_CLOCKWISE',
                    p: 'TOGGLE_PAUSE',
                    c: 'HOLD',
                    shift: 'HOLD'
                    }}
                >
                    {({
                        HeldPiece,
                        Gameboard,
                        PieceQueue,
                        points,
                        linesCleared,
                        state,
                        controller,
                        gameBoardClear
                    }) => {
                      setBoardCleared(gameBoardClear)
                      setGameScore(linesCleared)
                      return (
                        <Container>
                            <Box sx={{ flexGrow: 1 }} spacing={5} style={{ opacity: state === 'PLAYING' || 'PAUSED' ? 1 : 0.5, display: 'flex' }}>
                                <Grid item xs={4}>
                                    <MiddleColumn>
                                        <Gameboard />
                                    </MiddleColumn>
                                </Grid>
                                <Grid item xs={4}>
                                    <RightColumn>
                                        <PieceQueue />
                                    </RightColumn>
                                </Grid>
                            </Box>
                            <Grid>
                                {state === 'LOST' && (
                                    <Popup>
                                        <Alert>Game Over</Alert>
                                        <Button onClick={controller.restart}>Start</Button>
                                    </Popup>
                                )}
                            </Grid>
                        </Container>
                        )
                    }}
                </Tetris>
            </Container>
        </VerticallyCenterChildren>
    )
}