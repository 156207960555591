import React from 'react';
import { viewMatrix } from '../models/Game';
import { getClassName } from '../models/Piece';
import { Context } from '../context';

export default function GameboardView(): JSX.Element {
  const game = React.useContext(Context);
  const matrix = viewMatrix(game);

  //HiddenMessage
  const hiddenMessage = [
    ['W', 'I', 'L', 'L', ' ', 'Y', 'O', 'U', ' ', ' '],
    ['B', 'E', ' ', 'M', 'Y', ' ', ' ', ' ', ' ', ' '],
    ['V', 'A', 'L', 'E', 'N', 'T', 'I', 'N', 'E', '?']
  ]

  return (
    <table className="game-board">
      <tbody>
        {matrix.map((row, i) => {
          const blocksInRow = row.map((block, j) => {
            const classString = `game-block ${
              block ? getClassName(block) : 'block-empty'
            }`;
            
            if (i > 13 && i < 17) {
              //Hidden Message
              let hiddenMsgRow = hiddenMessage[i-14]
              let hiddenMsgBlock = hiddenMsgRow ? hiddenMsgRow[j] : 'NU'

              return <td key={j} className={classString} style={{textAlign: 'center'}} >
                {classString.includes('block-empty') || classString.includes('piece-preview') ? hiddenMsgBlock : null}
              </td>;
            } else {
              return <td key={j} className={classString}/>
            }
          });

          return <tr key={i}>{blocksInRow}</tr>;
        })}
      </tbody>
    </table>
  );
}
